function successCallback(api, _ref) {
  let {
    events,
    status,
    callback,
    unsub
  } = _ref;
  if (status.isFinalized) {
    // Filter for success events
    const eventList = events.filter(_ref2 => {
      let {
        event
      } = _ref2;
      return api.events.system.ExtrinsicSuccess.is(event);
    });
    if (eventList.length > 0) {
      if (callback) callback(); // If callback not undefined
      unsub();
    }
  }
}
export async function postRequestOpinion(api, pair, info, callback) {
  const unsub = await api.tx.opinionRequestor.requestOpinion(info).signAndSend(pair, {
    nonce: -1
  }, _ref3 => {
    let {
      events,
      status
    } = _ref3;
    successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}